import React from 'react'
import Layout from '../components/containers/layout'
import Navbar from '../components/nav/navbar'
import Footer from '../components/nav/footer'
import { Container } from 'react-bootstrap'

const ErrorPage = () => (
  <Layout>
    <Navbar />
    <h1 className="pt-5 text-center">Oops! Strony nie znaleziono</h1>
    <h3 className="pt-3 text-center">Strona nie istnieje lub została przeniesiona</h3>
    <Container fluid className="text-center">
      <a href="/">Strona główna</a>
    </Container>
    <Container fluid className="position-absolute p-0" style={{bottom: "0px"}}>
      <Footer />
    </Container>
  </Layout>
)
export default ErrorPage;
